/* eslint-disable no-unused-vars */
import callEndpoint from "@/services/api";
import METHODS from "@/services/api/apiMethods";
import axios from "axios";
import authHeader from "@/services/api/auth-header";
import {
  data
} from "autoprefixer";

let RESOURCE_URL = "";

if (process.env.NODE_ENV === "development") {
  RESOURCE_URL = process.env.VUE_APP_DEV_AUTH_SERVER_URL;
} else {
  RESOURCE_URL = process.env.VUE_APP_DEV_AUTH_SERVER_URL;
}
const {
  POST,
  //     PUT,
  PATCH,
  DELETE,
} = METHODS;

export const uploadToCloudinary = async (data) => {

  return axios.post(process.env.VUE_APP_CLOUDINARY_URL, data);
};
// https://res.cloudinary.com/demo/image/upload/f_auto,q_auto,e_trim,b_white/bo_1px_solid_gray/docs/rmv_bgd/stuffed.jpg
// Get countries and their states
const COUNTRY_BASE_URL = "https://countriesnow.space/api/v0.1/countries/states";

const CITY_BASE_URL =
  "https://countriesnow.space/api/v0.1/countries/state/cities";

const headers = {
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*'
};
const header = {
  'Content-Type': 'application/json',
  'Authorization': 'dskjdks',
  'sandbox-key': 'NrfBD4sIVcg7eGkAhlvVy1BWu6oEArIU1650754889'
}
export const getAllCountries = async () => {
  return axios.get(COUNTRY_BASE_URL, {
    headers
  });
};
export const getAllStatesByCountry = async (country) => {
  return axios.post(COUNTRY_BASE_URL, country, {
    headers
  });
};
export const getAllBanks = async () => {
  const url = 'https://fsi.ng/api/v1/flutterwave/v3/banks/NG?country=NG'
  return axios.get(url, {
    headers: header
  })
}

export const subscribeToNewsLetter = async (data) => {
  const url = `/api/subscribers/subscribe`
  return callEndpoint({
    method: POST,
    url,
    data
  })
}
export const getAllCitiesInAState = async (data) => {
  return axios.post(CITY_BASE_URL, data, {
    headers
  });
};

export const getLatLong = async (data) => {
  const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${data.houseNumber}+${data.streetName}+${data.city},+${data.state},+${data.country}
  &key=${process.env.VUE_APP_GOOGLE_MAP_API_KEY}`;
  return axios.get(url);
};

export const updatePassword = async (data) =>{
  const url = `/api/user/change-password/${data.email}`
  return callEndpoint({method:PATCH, url,data})
}

export const onBoardDeveloper = async (data) => {
  const url = `/api/developer/onboard`;
  return callEndpoint({
    method: PATCH,
    url,
    data,
    needsAuth: true
  });
};

export const getDeveloperData = async (data) => {
  const url = `/api/developer/get-developer-by-email`
  return callEndpoint({
    method:POST,url,data
  })
}

export const getDeveloperByApplicationUserId = async (userId) =>{
  const url = `/api/developer/get-developer/${userId}`
  return callEndpoint({url})
}

export const getAllTransportCompanies = async (data) =>{
  const url = `/api/v1/developer/access-transport-company/get-all-transport-companies`
  return callEndpoint({method:POST,url,data})
}

export const subscribeToTransportCompanies = async (data) =>{
  const url = `/api/v1/developer/access-transport-company/subscribe-to-transport-companies`
  return callEndpoint({method:POST,url,data})
}

export const unsubscribeFromTransportCompanies = async (data) =>{
  const url = `/api/v1/developer/access-transport-company/unsubscribe-to-transport-companies`
  return callEndpoint({method:POST,url,data})
}

export const  regenerateApiKey = async  (data) =>{
  const  url = `/api-key/regenerate`
  return callEndpoint({method:POST,url,data})
}

export const getTransactionHistory = async (developerId) => {
  const url = `/api/transaction/get-all/${developerId}`
  return callEndpoint({
    url
  })
}
export const sendOtpToPassenger = async (data) => {
  const url = `https://api.ng.termii.com/api/sms/otp/send`
  return axios.post(url, data)
}

export const verifyPassengerOtp = async (data) => {
  const url = `https://api.ng.termii.com/api/sms/otp/verify`
  return axios.post(url, data)
}

export const addBooking = async (data) => {
  const url = `/api/booking/add-booking/${data.tripId}`
  return callEndpoint({
    method: POST,
    url,
    data
  })
}


export const getWalletByUserEmail = async (data) =>{
  const url = `/api/wallet/get-wallet`
  return callEndpoint({method:POST,url,data},process.env.VUE_APP_NPAY_BASE_URL)
}

export const createPaymentLog = async (data) => {
  const url = '/api/payment-log/create'
  return callEndpoint({method:POST,url, data},process.env.VUE_APP_NPAY_BASE_URL)
}

export const getPaystackAccountDetails = async (email) =>{
  const url = `/api/paystack/get-account-by-user-email/${email}`
  return callEndpoint({url})
}

export const verifyPaystackPayment = async  (data) =>{
  const  url = `/api/paystack/verify-payment`
  return callEndpoint({method:POST,url,data},process.env.VUE_APP_NPAY_BASE_URL)
}

export  const withdrawFromWallet = async (data) =>{
  const url = `/api/wallet/transfer-funds-from-wallet`
  return callEndpoint({method:PATCH,url,data})
}

export const getWalletBalanceByUserEmail = async (email) =>{
  const url = `/api/wallet/get-wallet-balance/${email}`
  return callEndpoint({url})
}

const paystackHeaders = {
  'Content-Type': 'application/json',
  "Authorization":  'Bearer '+process.env.VUE_APP_PAYSTACK_LIVE_SECRET_KEY

};

export const  makePayment = async  (data) =>{
  const url = `https://api.paystack.co/transaction/initialize`
  return axios.post(url,data,{headers:paystackHeaders})
}