/* eslint-disable no-dupe-keys */
<template>
  <v-app-bar
    height="auto"
    elevation="0"
    fixed
    :color="bg === 'rgb(245 245 245);' ? 'transparent' : '#132534'"
    :class="[icon !== 'x' ? 'appBar-none' : 'appBar']"
    :style="{ background: bg }"
    class="ipad"
  >
    <page-layout>
      <template #Content>
        <div class="tw-flex tw-w-full">
          <div
            class="
              tw-hidden
              lg:tw-flex
              tw-w-full tw-items-center tw-py-5 tw-justify-between">
            <!-- web starts here -->
            <!-- first -->
            <img
              src="../../assets/whitelogo.png"
              width="146"
              height="40"
              alt="logo"
              class="tw-cursor-pointer"
              @click="routeToPages('Home')"
            />
            <!-- second -->
            <div
              class="tw-flex tw-flex-row tw-items-center"
              style="height: 40px"
            >
              <v-tabs
                color="transparent"
                slider-color="#004AAD"
                :hide-slider="
                  $route.name === 'HomePage' ||
                  $route.name === 'TransportCompanyRegister' ||
                  $route.name === 'TransportCompanyLogin'
                "
                v-model="currentTab"
              >
                <v-tab
                  @click="routeToPages(tab)"
                  v-for="tab in menus"
                  :key="tab" >{{ tab }}</v-tab>
              </v-tabs>
            </div>

            <!-- third -->
            <div class="tw-w-auto">
              <router-link to="/login" class="appBar-title tw-w-auto tw-mr-5"
                >Sign In</router-link
              >
              <router-link to="/register" class="appBar-title signup tw-py-2"
                >Get Started
              </router-link>
            </div>
          </div>

          <!-- web ends here -->
          <div
            class="lg:tw-hidden tw-w-full tw-flex tw-flex-col tw-h-auto"
            style="background-color: #132534">
            <div
              class="tw-flex tw-w-full tw-justify-between tw-items-center tw-px-6">
              <img
                src="../../assets/whitelogo.png"
                width="146"
                height="40"
                class="tw-cursor py-6"
                @click="routeToPages('Home')"
              />
              <vue-feather
                :type="icon"
                style="color: #fff"
                @click="toggelIcon"
                class="tw-cursor-pointer"
              />
            </div>
            <div
              class="tw-flex tw-flex-col tw-items-center tw-pb-5 anime"
              v-if="icon === 'x'"
              :class="[icon === 'x' ? 'anim' : '']"
              style="color: #fff"
            >
              <div
                v-for="menu in menus"
                :key="menu"
                class="tw-flex item-css pl-10"
              >
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <label
                      v-bind="attrs"
                      class="py-3"
                      v-on="on"
                      @click="routeToPages(menu)"
                      style="color: #fff"
                    >
                      {{ menu }}
                    </label>
                  </template>
                </v-menu>
              </div>
              <div class="item-css tw-flex pl-10 py-3">
                <label
                  class="sign-in tw-mr-5 tw-cursor-pointer"
                  style="color: #fff"
                  @click="$router.push({ name: 'Login' })">Sign In</label
                >
                <v-btn
                  class="get-started tw-p-2 tw-cursor-pointer"
                  style="color: #fff"
                  text
                  @click="$router.push({ name: 'Register' })">Get Started</v-btn
                >
              </div>
            </div>
          </div>
        </div>
      </template>
    </page-layout>
  </v-app-bar>
</template>

<script>
import PageLayout from "@/components/reuseables/PageLayout";
export default {
  name: "HomeNavbar",
  components: {
    PageLayout,
  },
  data() {
    return {
      icon: "menu",
      menus: ["Home", "About", "Documentation"],
      bg: "",
      currentTab: 0,
    };
  },
  watch: {
    "$route.name": {
      handler: function (val) {
        if (val === "Pricing") {
          console.log(val)
          this.currentTab = 3;
          console.log(this.currentTab)
        } else if (val === "Blog") {
          this.currentTab = 2;
        }
      },
      immediate: true,
    },
  },
  computed: {},
  methods: {
    toggelIcon() {
      if (this.icon === "menu") {
        this.icon = "x";
      } else {
        this.icon = "menu";
      }
    },
    routeToHome() {
      if (this.$route.name !== "HomePage")
        this.$router.push({ name: "HomePage" });
    },
    routeToPages(name) {
      if (name === "About") {
        window.open(process.env.VUE_APP_MAIN_FRONTEND_URL +"/about", "_self");
      } else if (name === "Blog") {
        window.open(process.env.VUE_APP_MAIN_FRONTEND_URL +"/blog", "_self");
      } else if (name === "Home") {
        if (this.$route.name !== "HomePage") {
          this.$router.push({name: "HomePage"});
        }
        else {
          window.open(process.env.VUE_APP_MAIN_FRONTEND_URL,"_self")
        }
      } else if (name === "Documentation") {
        this.$router.push({ name: "Register" });
      } else if (name === "Pricing") {
        if (this.$route.name !== "Pricing")
        this.$router.push({ name: "Pricing" });
      }
    },

    changeColor() {
      if (
        (document.body.scrollTop > 200) |
        (document.documentElement.scrollTop > 200)
      ) {
        this.bg = "#132534";
      } else {
        this.bg = "transparent";
      }
    },
  },
  mounted() {
    window.onscroll = () => {
      this.changeColor();
    };
  },
};
</script>

<style scoped lang="scss">
.theme--light.v-app-bar.v-toolbar.v-sheet {
  background-color: #132534 !important;
}
.v-tab {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #ffff !important;
  text-transform: none;
}
::v-deep .theme--light.v-tabs > .v-tabs-bar {
  background-color: transparent !important;
}
.ipad {
  z-index: 2;
  padding-right: 10rem !important;
  padding-left: 10rem !important;
  @media (max-width: 1400px) {
    padding: 0 5rem !important;
  }
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    padding-right: 0.4rem !important;
    padding-left: 0.4rem !important;
  }

  @media screen and (max-width: 768px) {
    padding-top: 1rem;
    padding-right: 0.4rem !important;
    padding-left: 0.4rem !important;
  }
}

.appBar-title {
  font-family: "Inter", serif !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-transform: none !important;
  text-align: left;
  color: #fff;
}

.item-css {
  width: 100%;
  height: fit-content;
  cursor: pointer;
  font-family: Inter, serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #154b94;
  transition: all ease-in-out 1000ms;
}

.anim {
  transition: 5s;
  transition-timing-function: ease-in-out;
  transform: translateY(0);
  opacity: 1;
  animation: infinite;
}

.item-css:hover {
  height: fit-content;
  background: rgba(124, 176, 246, 0.05);
  width: 100%;
  cursor: pointer;
}

.sign-in {
  font-family: Inter, serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #004aad;
}

.get-started {
  border: 1px solid #004aad;
  box-sizing: border-box;
  border-radius: 6px;
  font-family: Inter, serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #004aad;
}

.appBar-title.signup {
  background: #004aac;
  border-radius: 6px;
  color: #ffffff;
  padding-left: 1rem;
  padding-right: 1rem;
  width: 3rem !important;
}

.v-toolbar__content {
  padding: 4px 0px !important;
}

::v-deep .v-toolbar__content,
.v-toolbar__extension {
  @media screen and (max-width: 768px) {
    padding: 0 !important;
  }
}
</style>