import {getDeveloperData} from "@/services/api/APIService"
export default {

   async setDeveloperData({ commit }, data) {
       await getDeveloperData(data).then(res => {
            commit("SET_DEVELOPER_DATA", res.data);
        }).catch(err => {
            this.$displaySnackbar({
                message: err.response.data.details[0],
                success: false
            })})

    },
};