<template>
<div class="tw-flex tw-w-full tw-h-auto lg:tw-justify-center lg:tw-items-center lg:tw-flex-row"
     style="background: white;">
    <v-col sm="12" lg="7" class="tw-hidden  lg:tw-flex left-side">
      <v-col sm="12" class="left-side-body">
        <img src="@/assets/whitelogo.png" alt="logo" style="height: 40px; width: 186px" @click="$router.push({name : 'HomePage'})">
        <label class="buddy">Hi Dev!</label>
        <h6 class="welcome tw-mt-5">Welcome to Nomadicpod.</h6>
        <p class="admin tw-mt-5">Developer Dashboard</p>
      </v-col>
    </v-col>
    <v-col sm="12" lg="5" class="right-side">
      <v-col sm="12" class="right-side-body">
        <img src="@/assets/bluelogo.png" alt="logo" style="height: 34px; width: 136px" class="mb-3 mt-6" @click="$router.push({name : 'HomePage'})">

        <h6 class="signup-header mb-1 mb-md-5">Sign Up</h6>

        <p class="errorx" v-if="error" >{{error}}</p>
        <validation-observer ref="observer" v-slot="{ handleSubmit }">

          <form @submit.prevent="handleSubmit(signUpDeveloper)">
        <ValidationProvider name="Email" rules="required" v-slot="{ classes, errors }">
          <div class="tw-pt-4 mb-2" :class="classes">
            <v-text-field  placeholder="Email" solo hide-details type="email" v-model="signupData.email" required></v-text-field>
            <span>{{ errors[0] }}</span>
          </div>
        </ValidationProvider>
        <phone-number :phone-number-exist="signupData.companyPhoneNumber" @getNumber="getNumber"/>

            <ValidationProvider name="Confirm Password" rules="required" v-slot="{ classes, errors }">
              <div class="tw-pt-4 mb-2" :class="classes">
                <v-text-field color="#1E5770"
                              hide-details
                              :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'"
                              @click:append="() => (value = !value)"
                              :type="value ? 'text' : 'password'"
                              solo
                              placeholder="Confirm Password"
                              name="password"
                              v-model="signupData.password"
                              id="password">
                </v-text-field>
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>


        <base-button  class="mt-4" width="100%" color="#1E5770" button-text="Sign Up" :loading="loading" type="submit"></base-button>
        
        <div class="tw-pt-4">
          <h6 class=" tw-flex already  tw-items-center tw-justify-center">
            Already have an account? <router-link :to="{name: 'Login'}" style="color: #1E5770; cursor: pointer">&nbsp;Login</router-link>
          </h6>
        </div>
          </form>
        </validation-observer>
      </v-col>
    </v-col>
  <modal :dialog="dialog" title="Thank you"  additional-text="We just sent you a confirmation email.
  Please check your inbox to confirm your  email." @close="toggleModal" :icon="check" />
</div>
</template>

<script>

import Modal from "../components/reuseables/Modal";
import BaseButton from "../components/reuseables/BaseButton";
import check from '../assets/check_one.svg'
import {developerSignUp} from "@/services/api/AuthApiService";
import PhoneNumber from "@/components/reuseables/PhoneNumber.vue";
export default {
  name: "DeveloperRegisterView",
  components: {PhoneNumber, BaseButton, Modal},
  data(){
    return{
      title: "Welcome to RoadPadi",
      description: "Transport Company Admin Board",
      value : false,
      value2: false,
      confirmValue : false,
      dialog : false,
      loading: false,
      check,
      agree : false,
      signupData:{
        email: "",
        password : "",
        confirmPassword: "",
        companyPhoneNumber : ""
      },
      error:""
    }
  },
  methods:{
    toggleModal(){
      if (this.dialog){
        this.$router.push({name:'Login'})
      }
      this.dialog = !this.dialog
    },
    getNumber(number){
      this.signupData.companyPhoneNumber = number
    },
    signUpDeveloper(){
      if (this.validEmail(this.signupData.email)) {
        this.loading = true
        developerSignUp(this.signupData).then(res => {
          console.log(res)
          this.$displaySnackbar({
            message: "Signed-up Successfully",
            success: true
          })
          this.dialog = true
          this.loading = false
        })
            .catch(err => {
              console.log(err.response)
              this.$displaySnackbar({
                message: err.response.data.details[0],
                success: false
              })
              this.loading = false
            })
      }
      else {
        this.error = "Password mismatch try again"
        setInterval(() =>{
          this.error = ""
        },5000)
        // this.error = ""
      }
  },
    validEmail(email) {
      let valid = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (email.match(valid)) {
        return true;
      } else {
        this.error = "Not a valid Email"
        setInterval(() =>{
          this.error = ""
        },5000)
        // this.error = ""
        return false;
      }
    },

  }
}
</script>

<style scoped lang="scss">
@media screen and (min-width: 1024px){
  .right-side-body > img {
    display: none;
  }

  .right-side-body .welcome{
    display: none;
  }
  .right-side-body .admin{
    display: none;
  }
}

.right-side-body .welcome{
  font-family: 'Inter';
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 22px;
  /* identical to box height */
  letter-spacing: 0.02em;
  color: #4F4F4F;
  text-shadow: 0px 4px 40px rgba(0, 0, 0, 0.25);
  margin-left: 0rem;
}

.right-side-body .admin{
  font-family: 'Inter',serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.05em;
  color: #4F4F4F;
  margin-left: 0rem;
}

.title{
  font-family: "DM Sans" !important;
  font-style: normal;
  font-weight: bold;
  font-size: 20px !important;
  line-height: 26px;
  color: #004AAD;
}
.input{
 width: 48%;
  @media screen  and (max-width: 1024px){
    width: 100%;
  }
}
.agree{
  font-family: "DM Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.2px;
  color: rgba(0, 0, 0, 0.7);
}
.have-account{
  font-family: "DM Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
}

.left-side{
  width: 100%;
  min-height: 100vh;
  height: 100%;
  background-image: url('../assets/register-bg.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0!important;

  @media screen and (max-width: 1024px){
    display: none;
  }
}
.left-side-body{
  display: flex;
  width: 100%;

  flex-direction: column;
  padding:5rem 7rem;
  justify-content: center;


}
.buddy{
  font-family: "Inter", serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.05em;
  color: #FFFFFF;
  margin-top: 4rem;
  margin-left: 0.8rem;
}
.welcome{
  font-family: "Inter",serif;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 35.2px;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  text-shadow: 0px 4px 40px rgba(0, 0, 0, 0.25);
  margin-left: 0.8rem;
  @media screen and (max-width: 1024px) {
    margin-top: 35px;
  }
}
.admin{
  font-family: "Inter",serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.05em;
  color: #FFFFFF;
  margin-left: 0.8rem;
}

.right-side{
  width: 100%;
  min-height: 100vh;
  height: auto;
  padding: 0!important;
  background: white;
  align-items: center;
  display: flex;

  @media screen and (max-width: 820px) {
    align-items: flex-start;
  }

}

.right-side-body{
  display: flex;
  width: 100%;
  flex-direction: column;
  padding:7rem;
  justify-content: center;
  @media screen and (max-width: 1440px ){
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  @media screen and (max-width: 1366px ) and (min-width: 1025px){
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  @media screen and (max-width: 1024px ){
    padding: 1.5rem;
    justify-content: flex-start;
  }
}

.signup-header{
  font-family: "Inter", serif;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 64px;
  color: #1E5770;
;
  @media screen and (max-width: 1024px) {
      margin-top: 20px;
    }
}

.terms{
  font-family: "Inter",serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;
  color: #4F4F4F;
}

.already{
  font-family: "Inter", serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 22px !important;
  color: #4F4F4F !important;
}

::placeholder {
  color: red !important;
}

.errorx{
  color: red;
  font-size: 14px;
  font-family: "Inter", sans-serif !important;

}
</style>