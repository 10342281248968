<template>
  <div>
    <Navbar />
    <div class="main-layout-wrapper">
      <section class="first-section tw-pb-20 lg:tw-pb-0 body-bg">
        <div class="lg:tw-px-5 tw-px-2 tw-mt-20">
          <v-row class="tw-items-center tw-justify-center tw-h-screen">
            <v-col sm="12" class="tw-px-8 lg:tw-pl-40 lg:tw-pr-0 ipad">
              <p class="text-header md:tw-pt-0">
                Build Travel Applications quicker and faster with our Complete
                API Suite.
              </p>
              <p class="text-subheader md:tw-mt-5 tw-mt-8">
                The simplest way to build travel booking applications and start
                facilitating bookings in minutes.
              </p>
              <img class="mobile-phone lg:tw-hidden" alt="" />
              <div class="tw-flex md:tw-mt-20 tw-mt-12 tw-flex-wrap">
                <router-link
                  to="/register"
                  class="
                    fs-title
                    signin
                    text-center
                    tw-flex tw-items-center tw-justify-center tw-px-5 tw-py-4
                  "
                  >Get Started</router-link
                >
              </div>
            </v-col>
          </v-row>
        </div>
      </section>
      <section class="second-section">
        <div class="tw-hidden lg:tw-flex tw-w-1/2">
          <img
            class="second-section-img"
            src="@/assets/second-sec.png"
            alt=""
          />
        </div>
        <div class="tw-flex tw-w-full lg:tw-w-1/2 tw-flex-col">
          <h6 class="second-section-header">
            Build Custom Travel experience with well documented APIs
          </h6>
          <h6 class="second-section-subtext tw-mt-8">
            Developers love our thorough, well-documented APIs that lets you
            build easily. Build your business on our infrastructure and only pay
            for as much as you use.
          </h6>
          <h6 class="section-link tw-mt-8" style="color: #004aad">
            <vue-feather type="link-2" class="tw-mr-3" /> Start Using Our APIs
          </h6>
        </div>
      </section>
      <section class="third-section tw-hidden">
        <div class="tw-flex lg:tw-w-1/2 tw-w-full tw-flex-col">
          <h6 class="second-section-header">
            Get Decentalized Access to Travel Assets
          </h6>
          <h6 class="second-section-subtext tw-mt-8">
            Access travel data, facilities and services of over 50 transport
            companies with our cutting edge API infrastructure, built for
            businesses to help you get started quicker.
          </h6>
        </div>
        <div
          class="
            tw-flex
            lg:tw-w-1/2
            tw-w-full
            md:tw-flex-wrap md:tw-justify-center
            lg:tw-flex-row
            tw-flex-col
          "
        >
          <div
            class="third-section-free tw-p-5 tw-flex-col tw-my-14 lg:tw-my-0"
          >
            <h6 class="third-section-header" style="color: #004aad">
              Free Resource
            </h6>
            <h6 class="third-section-desc">
              Get access to several of our resources for free
            </h6>
            <h6 class="section-link tw-mt-8" style="color: #004aad">
              <vue-feather type="link-2" class="tw-mr-3" /> Access Free
              Resources
            </h6>
          </div>
          <div
            class="third-section-paid tw-p-5 tw-flex-col md:tw-ml-0 lg:tw-ml-20"
          >
            <h6 class="third-section-header" style="color: #ba68c8">
              Paid Resource
            </h6>
            <h6 class="third-section-desc">
              Get access to business specific API’s for a small token
            </h6>
            <h6 class="section-link tw-mt-8" style="color: #ba68c8">
              <vue-feather type="link-2" class="tw-mr-3" /> Access Paid
              Resources
            </h6>
          </div>
        </div>
      </section>
      <section class="second-section">
        <div class="tw-hidden lg:tw-flex tw-w-1/2">
          <img
            class="second-section-img"
            src="@/assets/fourth-sec.png"
            alt=""
          />
        </div>
        <div class="tw-flex tw-w-full lg:tw-w-1/2 tw-flex-col">
          <h6 class="second-section-header">Personalized Traveller Code</h6>
          <h6 class="second-section-subtext tw-mt-8">
            Now your customers can fill in their travel details once and for
            all, and reuse it at any transport services for a life time.
          </h6>

          <div class="tw-flex tw-mt-12 tw-flex-wrap">
            <div
              @click="bookingCode"
              class="
                fs-title
                signin
                text-center
                tw-cursor-pointer
                tw-flex
                tw-items-center
                tw-justify-center
                tw-px-4
                tw-py-5
              "
            >
              Get Traveller Code
            </div>
          </div>
        </div>
      </section>
      <next-to-footer />
      <Footer />
    </div>
  </div>
</template>

<script>
import Navbar from "../components/reuseables/Navbar";
import Footer from "../views/Footer";
import NextToFooter from "../components/reuseables/NextToFooter";

export default {
  name: "HomePage",
  components: { NextToFooter, Navbar, Footer },

  methods: {
    bookingCode() {
      window.open(process.env.VUE_APP_MAIN_FRONTEND_URL+"/traveller-code", "_blank");
    },
    getYear() {
      let date = new Date();
      this.year = date.getFullYear();
    },
  },
  mounted() {
    this.getYear();
  }
};
</script>

<style lang="scss" scoped>
.test-section {
  @media screen and (min-width: 768px) {
    height: 100vh;
    .card-div {
      height: 328px;
    }
  }
  //background-image: url("../assets/sec-three.svg");
  object-fit: cover;
  background-size: cover;
  .header {
    font-family: "Raleway", sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 46px;
    letter-spacing: 0em;
    text-align: center;
    height: 112px;
    width: 573px;
    color: #263238;
    @media screen and (max-width: 768px) {
      font-size: 26px;
      line-height: 40px;
      height: 120px;
      width: 295px;
    }
  }
  .sub-text {
    height: 108px;
    width: 407px;
    font-family: "Inter", sans-serif !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0;
    text-align: center;
    color: #263238;
    @media screen and (max-width: 768px) {
      height: 90px;
      width: 285px;
      font-size: 16px;
      line-height: 30px;
      letter-spacing: 0;
    }
  }
  .position-image {
    position: absolute;
    right: 0;
    top: 20px;
  }

  .text-box {
    font-family: "Raleway", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: center;
    color: rgba(0, 74, 173, 1);
    p {
      margin: 0 !important;
    }

    @media screen and (max-width: 768px) {
      font-family: "Raleway", sans-serif;
      padding: 0px 40px;
      font-size: 22px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px;
      letter-spacing: 0em;
      text-align: center;
    }
  }
}

.main-layout-wrapper {
  overflow-y: clip;
}
.body-bg {
  background-image: url("../../src/assets/lpbg.png");
  object-fit: cover;
  background-size: cover;

  @media screen and (max-width: 768px) {
    background-size: cover;
    background-image: url("../assets/lpmobile.png");
    background-position: center;
  }
}

.first-section {
  min-height: 100vh;
  .hide-col {
    display: flex;
    justify-content: left;
    height: auto;
    padding-left: 5rem;
    img {
      height: available;
      @media screen and (min-width: 1920px) {
        width: fit-content;
        padding-left: 0;
      }
    }
    @media screen and (max-width: 1024px) {
      display: none;
    }
  }

  .text-header {
    font-family: "Raleway", sans-serif !important;
    font-size: 40px;
    font-weight: 600;
    font-style: normal;
    line-height: 54px;
    letter-spacing: 0em;
    text-align: left;
    color: #fff;
    width: 40%;
    @media screen and (min-width: 1920px) {
      font-size: 50px;
      line-height: 60px;
    }
    @media screen and (max-width: 1024px) {
      width: 70%;
    }
    @media screen and (max-width: 768px) {
      font-size: 32px;
      font-weight: 600;
      line-height: 48px;
      letter-spacing: 0;
      width: 100%;
    }
  }

  .text-subheader {
    font-family: "Inter", sans-serif !important;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    color: #fff;
    width: 40%;
    @media screen and (min-width: 1920px) {
      font-size: 22px;
      line-height: 32px;
    }
    @media screen and (max-width: 1024px) {
      width: 70%;
    }
    @media screen and (max-width: 768px) {
      font-size: 16px;
      line-height: 28px;
      width: 100%;
    }
  }

  .fs-title {
    width: 152px;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.01em;
    text-align: left;
  }
  .signin {
    background: #004aac;
    border-radius: 6px;
    color: white;
  }
  .signup {
    color: rgba(0, 74, 173, 1);
  }
}

.ipad {
  @media screen and (max-width: 1024px) {
    padding-left: 5rem;
  }
  @media screen and (max-width: 768px) {
    padding-left: 2rem;
  }
}
.second-section {
  display: flex;
  min-height: 100vh;
  background-color: #fdfffc;
  padding-left: 15rem;
  padding-right: 15rem;
  align-items: center;

  @media screen and (max-width: 1024px) {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  @media screen and (max-width: 768px) {
    padding-right: 2rem;
    padding-left: 2rem;
  }
}
.second-section-img {
  width: 488px;
  height: 386px;
  @media screen and (max-width: 1440px) {
    width: 388px;
    height: 286px;
  }
}

.second-section-header {
  font-family: "DM Sans", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 48px;
  color: #4f4f4f;
  width: 70%;
  @media screen and (max-width: 1440px) {
    font-weight: 600;
    font-size: 26px;
    line-height: 40px;
    width: 100%;
  }
}

.second-section-subtext {
  font-family: "Inter", sans-serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 36px;
  color: #4f4f4f;
  width: 70%;
  @media screen and (max-width: 1440px) {
    width: 100%;
  }
}
.section-link {
  display: flex;
  font-family: "DM Sans", sans-serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  width: 100%;
}
.sub-text {
  height: 108px;
  width: 407px;
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(42, 70, 108, 1);
  @media screen and (max-width: 768px) {
    height: 90px;
    width: 285px;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0em;
  }
}

.third-section {
  display: none;
  min-height: 100vh;
  width: 100%;
  background-image: url("../assets/third-sec-bg.svg");
  background-size: cover;
  background-color: #ffffff;
  align-items: center;
  padding-left: 10rem;
  padding-right: 10rem;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding: 4rem 2rem;
  }
}
.third-section-free {
  display: flex;
  width: 272px;
  height: 270px;
  background-image: url("../assets/free.png");
  background-size: cover;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4.23529px 21.1765px rgba(0, 0, 0, 0.05);
  @media screen and (max-width: 1440px) and (min-width: 1024px) {
    width: 232px;
    height: 230px;
  }
}

.third-section-paid {
  display: flex;
  width: 272px;
  height: 270px;
  background-image: url("../assets/paid.png");
  background-size: cover;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4.23529px 21.1765px rgba(0, 0, 0, 0.05);
  @media screen and (max-width: 1440px) and (min-width: 1025px) {
    width: 232px;
    height: 230px;
    margin-left: 2rem;
  }
  @media screen and (max-width: 1440px) and (min-width: 1023px) {
    width: 232px;
    height: 230px;
    margin-left: 0;
  }
  @media screen and (max-width: 1025px) and (min-width: 770px) {
    margin-top: 2rem;
  }
}

.third-section-header {
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 48px;
  width: 100%;
}

.third-section-desc {
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #263238;
}

.fourth-section {
  @media screen and (min-width: 768px) {
    height: 100vh;
    .back-img {
      //background-image: url("../assets/fourth-back.svg");
      background-position: center;
      background-size: contain;
      object-fit: fill;
      background-repeat: no-repeat;
    }
  }

  @media screen and (max-width: 768px) {
    .back-img {
      height: 700px;
      background-image: url("../assets/homepage/four-mobile-bg.svg");
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  .back-img {
    .first-box {
      .header {
        font-family: Raleway, sans-serif !important;
        font-style: normal;
        font-weight: 600;
        font-size: 36px;
        line-height: 56px;
        color: #263238;
        width: 60%;
        text-align: start;
        @media screen and (max-width: 768px) {
          font-size: 22px;
          line-height: 30px;
        }
      }
      .sub-text {
        font-family: Inter, sans-serif !important;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 32px;
        color: #263238;
        width: 50%;
        text-align: start;
        @media screen and (max-width: 768px) {
          height: 140px;
          width: 234px;
          font-family: "Inter", sans-serif !important;
          font-size: 14px;
          line-height: 28px;
        }
      }
    }
  }

  .back-img {
    .second-box {
      justify-content: flex-start;
      .header {
        font-family: Raleway, sans-serif !important;
        font-style: normal;
        font-weight: 600;
        font-size: 36px;
        line-height: 56px;
        color: #263238;
        @media screen and (min-width: 769px) and (max-width: 1024px) {
          width: 50%;
        }
        width: 40%;
        align-self: flex-end;
        @media screen and (max-width: 768px) {
          height: 60px;
          width: 300px;
          font-size: 22px;
          line-height: 30px;
        }
      }
      .sub-text {
        font-family: Inter, sans-serif !important;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 32px;
        color: #263238;
        @media screen and (min-width: 769px) and (max-width: 1024px) {
          width: 50%;
        }
        width: 40%;
        align-self: flex-end;
        @media screen and (max-width: 768px) {
          height: 140px;
          font-size: 14px;
          line-height: 22px;
          width: 79%;
          padding-right: 2rem;
        }
        @media screen and (max-width: 324px) {
          width: 98%;
        }
      }
    }
  }
}

.fifth-section {
  @media screen and (min-width: 768px) {
    height: 100vh;
  }
  //background-image: url("../assets/fifth-back.svg");
  background-size: cover;
  background-position: center;

  .first-header {
    font-family: "Raleway", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 56px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(0, 74, 173, 1);
    @media screen and (max-width: 768px) {
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 56px;
      letter-spacing: 0em;
      text-align: left;
    }
  }

  .text-header {
    font-family: Raleway, serif !important;
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 56px;
    color: #263238;
    width: 70%;
    @media screen and (max-width: 768px) {
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: 48px;
      letter-spacing: 0;
      text-align: left;
      width: 100%;
    }
  }

  .sub-text {
    font-family: Inter, serif !important;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 32px;
    color: #263238;
    width: 50%;
    @media screen and (max-width: 768px) {
      width: 100%;
    }

    @media screen and (max-width: 768px) {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: 0em;
      text-align: left;
    }
  }

  .btn {
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.01em;
    text-align: left;
    /* height: 24px; */
    border-radius: 6px;
    width: 253px;
    border: 2px solid rgba(0, 74, 173, 1);
    color: rgba(0, 74, 173, 1);
    padding: 14px 26px 14px 37px;

    @media screen and (max-width: 768px) {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0.01em;
      text-align: left;
    }
  }

  .second-col {
    @media screen and (max-width: 768px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    position: relative;
    .web-img {
      @media screen and (max-width: 768px) {
        display: none;
      }
    }

    .mobile-img {
      @media screen and (min-width: 768px) {
        display: none;
      }
      width: 436px;
      margin: 0 auto;
      z-index: 1;
    }

    .first-box,
    .second-box,
    .third-box {
      @media screen and (max-width: 768px) {
        margin-top: 10px;
      }
      @media screen and (min-width: 768px) {
        display: none;
      }
    }

    .first-box {
      height: 211px;
      width: 221px;
      border-radius: 10px;
      background: rgba(253, 255, 252, 1);
      border: 2px solid rgba(198, 70, 122, 0.1);
      box-sizing: border-box;
      box-shadow: 0 4px 60px rgba(0, 74, 173, 0.1);

      @media screen and (max-width: 768px) {
        margin-top: 3rem;
        height: max-content;
        padding-top: 1rem;
        padding-bottom: 1rem;
      }

      .header {
        font-family: "Inter", serif !important;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0;
        text-align: left;
        color: rgba(124, 176, 246, 1);
      }

      .sub-text {
        font-family: "Inter", serif !important;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0;
        text-align: left;
        color: rgba(111, 127, 148, 1);
      }
    }

    .second-box {
      height: 219px;
      width: 221px;
      border-radius: 10px;
      background: rgba(253, 255, 252, 1);
      border: 2px solid rgba(198, 70, 122, 0.1);
      box-sizing: border-box;
      box-shadow: 0 4px 60px rgba(0, 74, 173, 0.1);

      @media screen and (max-width: 768px) {
        margin-top: 3rem;
        height: max-content;
        padding-top: 1rem;
        padding-bottom: 1rem;
      }
      .header {
        height: 24px;
        width: 150px;
        font-family: "Inter", serif !important;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(200, 72, 103, 1);

        @media screen and (max-width: 768px) {
          height: auto;
        }
      }

      .sub-text {
        font-family: "Inter";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(111, 127, 148, 1);
      }
    }

    .third-box {
      height: 187px;
      width: 200px;
      background: #ba68c8;
      border: 2px solid rgba(198, 70, 122, 0.1);
      box-sizing: border-box;
      box-shadow: 0 4px 60px rgba(0, 74, 173, 0.12);
      border-radius: 10px;

      @media screen and (max-width: 768px) {
        margin-top: 3rem;
        height: max-content;
        padding-top: 1rem;
        padding-bottom: 1rem;
      }

      .header {
        font-family: "Inter";
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(255, 255, 255, 1);
      }

      .sub-text {
        font-family: "Inter";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(255, 255, 255, 1);
      }
    }
  }
}

.sixth-section {
  @media screen and (min-width: 768px) {
    height: 100vh;
    padding: 30px 0;

    .section-img {
      margin-top: -5rem;
      height: 280px;
      width: 332px;
    }
  }

  .header-div {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    .header-div-text {
      font-family: Raleway, serif !important;
      font-style: normal;
      font-weight: 600;
      font-size: 36px;
      line-height: 56px;
      text-align: center;
      color: #263238;
      width: 518px;
      height: 112px;
      align-self: center;
      @media screen and (max-width: 768px) {
        height: 80px;
        width: 317px;
        font-size: 28px;
        line-height: 40px;
      }
    }
  }

  .vert-line {
    width: 55px;
    height: 0px;
    margin: 20px 0;
    border: 5px dashed #004aad;
    transform: rotate(90deg);

    @media screen and (min-width: 768px) {
      display: none;
    }
  }
  .box-div {
    padding: 20px;
    position: relative;
    .position-img {
      @media screen and (max-width: 1024px) {
        display: none;
      }
      position: absolute;
      top: 60%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .text-div {
      margin-top: -50px;
      .header {
        font-family: Raleway, serif !important;
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 36px;
        text-align: center;
        color: #263238;
        @media screen and (max-width: 768px) {
          font-family: "Raleway";
          font-size: 22px;
          font-style: normal;
          font-weight: 600;
          line-height: 36px;
          letter-spacing: 0em;
          text-align: center;
        }
      }

      .sub-text {
        font-family: Inter, serif !important;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 32px;
        text-align: center;
        color: #263238;
        width: 336px;
        @media screen and (max-width: 768px) {
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 32px;
          letter-spacing: 0em;
          text-align: center;
        }
      }
    }
  }
}

.seventh-section {
  @media screen and (min-width: 1024px) {
    height: 100vh;
  }
  background-size: cover;
  //background: rgba(0, 74, 173, 0.04) url("../assets/seventh.svg") center;

  .header-div {
    .header-div-text {
      font-family: Raleway, serif !important;
      font-style: normal;
      font-weight: 600;
      font-size: 40px;
      line-height: 56px;
      text-align: center;
      color: #263238;
      @media screen and (max-width: 768px) {
        font-family: Raleway, serif !important;
        font-size: 28px;
        font-style: normal;
        font-weight: 600;
        line-height: 56px;
        letter-spacing: 0;
        text-align: center;
      }
    }
  }

  .box:first-child {
    .sub-text {
      height: 72px;
      width: 204px;
      @media screen and (max-width: 768px) {
        height: 48px;
        width: 160px;
      }
    }
  }

  .box {
    position: relative;
    .position-img {
      position: absolute;
      top: 44%;
      left: 96%;
      transform: translate(-50%, -50%);

      @media screen and (max-width: 768px) {
        position: absolute;
        top: 236px;
        left: 35%;
        transform: rotate(90deg);
      }
      @media screen and (min-width: 769px) and (max-width: 1024px) {
        display: none;
      }
    }

    .header {
      font-family: Inter, serif !important;
      font-style: normal;
      font-weight: 500;
      font-size: 28px;
      line-height: 36px;
      text-align: center;
      color: #263238;
      @media screen and (max-width: 768px) {
        font-family: Inter, serif !important;
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: 36px;
        letter-spacing: 0em;
        text-align: center;
      }
    }
    .sub-text {
      font-family: Inter, serif !important;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 28px;
      text-align: center;
      color: #2a466c;
      @media screen and (max-width: 768px) {
        font-family: Inter, serif !important;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0;
        text-align: center;
      }
    }
  }
}

.signup {
  width: 172px;
  color: white;
}

.signin {
  background: #004aac;
  border-radius: 6px;
  color: white;
}
</style>
