<template>
    <loader v-if="loading" :loading="loading"/>
    <div v-else class="dashboard lg:tw-px-32 lg:tw-pt-16">
        <h6 class="username tw-flex md:tw-hidden">Hi {{developerData.firstname}},</h6>
        <div class="body">
            <div class="first-column">
                <v-card class="card-1 tw-p-3" flat>
                    <img src="../assets/purse.png" style="width:32px; height:auto; border-radius: 0" class="mx-5"
                        alt="purse">
                    <v-card-text>Your wallet balance is</v-card-text>
                    <div class="tw-full-width tw-flex tw-flex-wrap" style="justify-content: space-between;">
                        <v-card-title v-if="walletData">₦ {{formatWithCommas(walletData.balance)}}</v-card-title>
                        <v-card-actions>
                            <v-btn outlined rounded text style="text-transform: none"
                                @click="handleShowFundWalletModal">
                                Fund Wallet
                            </v-btn>
                        </v-card-actions>
                    </div>
                </v-card>
              <div class="tw-hidden lg:tw-flex lg:tw-mt-20"></div>
              <div class="card-2 ">
                <vue-feather type="key" style="color: #004AAD; font-size:50px " width="30" height="30"
                             class="px-4 "></vue-feather>
                <span class="api-title">Authorization Server</span>
                <div class="api-input">
                  <v-text-field hide-details outlined :value="authServer" readonly
                                color="blue darken-3">
                    <template #append>
                      <v-icon :color="copiedApiKey ? '#004AAD': ''"
                              @click="copyCode(developerData.apiKey,'apiKey')">
                        {{ copiedApiKey ? "mdi-check-all" : "mdi-card-multiple-outline" }}
                      </v-icon>
                    </template>
                  </v-text-field>
                </div>
              </div>
              <div class="card-2">
                <vue-feather type="layers" style="color: #004AAD; font-size:50px " width="30" height="30"
                             class="px-4 "></vue-feather>
                <span class="api-title">Resource Server</span>
                <div class="api-input">
                  <v-text-field hide-details outlined :value="resourceServer" readonly color="blue darken-3">
                    <template #append>
                      <v-icon :color="copiedBaseUrl ? '#004AAD': ''" @click="copyCode(resourceServer,'baseUrl')">
                        {{ copiedBaseUrl ? "mdi-check-all" : "mdi-card-multiple-outline" }}
                      </v-icon>
                    </template>
                  </v-text-field>
                </div>
              </div>
<!--                <div class="card-2 ">-->
<!--                    <vue-feather type="link" style="color: #004AAD; font-size:50px " width="30" height="30"-->
<!--                        class="px-4 "></vue-feather>-->
<!--                    <span class="api-title">API key</span>-->
<!--                    <div class="api-input">-->
<!--                        <v-text-field hide-details outlined :value="developerData.apiKey" readonly-->
<!--                            color="blue darken-3">-->
<!--                            <template #append>-->
<!--                                <v-icon :color="copiedApiKey ? '#004AAD': ''"-->
<!--                                    @click="copyCode(developerData.apiKey,'apiKey')">-->
<!--                                    {{ copiedApiKey ? "mdi-check-all" : "mdi-card-multiple-outline" }}-->
<!--                                </v-icon>-->
<!--                            </template>-->
<!--                        </v-text-field>-->
<!--                    </div>-->
<!--                </div>-->

            </div>
            <div class="second-column">
                <v-card class="card-4 tw-p-3" flat>
                    <v-card-text class="four-text">Spendings</v-card-text>
                    <div class="tw-full-width tw-flex tw-flex-wrap" style="justify-content: space-between;">
                        <v-col lg="4" class="wallet">
                            <v-img src="../assets/wallet.png" style="width:44.71px; height:48.33px"></v-img>

                        </v-col>
                        <v-col style="padding:0 !important" class="amount-spent">
                            <v-card-title>₦{{formatWithCommas(monthlySpending)}}</v-card-title>
                            <v-card-subtitle class="py-2 ">Spent this month</v-card-subtitle>
                        </v-col>
                    </div>
                </v-card>
                <v-card class="card-5 tw-p-3" style="background: white">
                    <v-card-title>Transport Companies</v-card-title>
                    <div class="chart-div tw-w-full tw-flex">
                        <apexchart width="250" height="250" type="donut" :options="options" :series="getSeries">
                        </apexchart>
                    </div>
                  <div class="tw-flex tw-justify-between tw-flex-row ">
                    <div style="padding-left:10px" v-if="transportCompanies">
                      <h1 style="font-weight: 500;font-size: 20px;">{{transportCompanies.length}}</h1>
                      <p style="font-weight: 400;font-size: 12px;">Total</p>
                    </div>
                    <div style="padding-left:10px">
                      <h1 style="font-weight: 500;font-size: 20px;">{{developerData.transportCompaniesSubscribeTo.length}}</h1>
                      <p style="font-weight: 400;font-size: 12px;">Subscribed</p>
                    </div>
                  </div>

                </v-card>

            </div>
            <div class="third-column">

                <v-card class="transaction-div tw-p-3 tw-pt-8">
                    <div class="tw-flex tw-flex-wrap" style="justify-content:space-between; margin: 0 30px 0 10px">
                        <v-card-title class="cardqueen-title">Transactions</v-card-title>
                        <v-btn color="#004AAD" @click="routeToWallet">View</v-btn>
                    </div>

                    <div class="cardqueen">
                        <v-card class="card-6" flat v-for="(transaction, index) in walletData.transactions" :key="index">
                            <label class="six-text"
                                :style="{color: transaction.paymentType === 'CREDIT' ? '#004AAD' : '#BA68C8'}">
                                {{transaction.paymentType}} </label>

                            <label class="amount"> {{formatWithCommas(transaction.amount)}} </label>
                            <label class="six-text2"> {{getDate(transaction.createdAt)}} </label>
                        </v-card>


                    </div>
                </v-card>

            </div>
        </div>

      <deposit-modal  :show-modal="showFundWalletModal"
                      header="Enter amount to deposit" width="500px"
                      color="#004AAD" @closeModal="handleShowFundWalletModal" @action="handleShowPaymentModal" />

      <paystack
          :amount="depositAmount*getKobo"
          :email="developerData.email"
          :paystackkey="getPayStackKey"
          :reference="reference"
          :callback="handleVerifyPaysackPayment"
          :close="handleClosePaytackModal"
          :embed="false"
          :channels= "['card','bank_transfer']"
          :subaccount="walletData.paystackSubAccountCode"
      >
        <div class="tw-hidden" ref="paystack">Pay</div>
      </paystack>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import {
  createPaymentLog,
  getAllTransportCompanies,
  getWalletByUserEmail,
  verifyPaystackPayment
} from "@/services/api/APIService";
import depositModal from "@/components/reuseables/DepositModal.vue";
import paystack from "vue-paystack";
import dayjs from "dayjs";
import Loader from "@/components/reuseables/Loader.vue";

export default {
        components: {
          Loader,
          paystack,
          depositModal,
        },
        data() {
            return {
                options: {
                    labels: ["Total", "Subscribed"],
                    legend: {
                        position: 'bottom'
                    },
                    dataLabels: {
                        enabled: false
                    },
                    colors: ['#0A44AD', '#9227AD'],
                },

                series: [0, 0],


                showFundWalletModal: false,
                resourceServer: "",
                authServer: "",
                copiedApiKey: false,
                copiedBaseUrl: false,
              depositAmount : null,
              walletData:{},
              monthlySpending: null,
              loading : false,
              reference:null
            }
        },
        computed: {
            ...mapGetters("developerData", ["developerData"]),
          ...mapGetters("companyStore",["transportCompanies"]),
          getKobo(){
            return process.env.VUE_APP_KOBO
          },
          getPayStackKey(){
            return process.env.VUE_APP_PAYSTACK_LIVE_PUBLIC_KEY
          },
          getSeries(){
            return [ this.transportCompanies.length, this.developerData.transportCompaniesSubscribeTo.length]
          }
        },
        methods: {
          getReference(){
            let text = "";
            let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

            for( let i=0; i < 10; i++ )
              text += possible.charAt(Math.floor(Math.random() * possible.length));
            this.reference = text;
          },
          async createFundWalletPaymentLog(depositAmount){
            let userData = JSON.parse(sessionStorage.getItem("userData"))
            this.getReference()
            let paymentLog = {}
            paymentLog.amount = depositAmount
            paymentLog.currency="NGN"
            paymentLog.reference= this.reference
            paymentLog.service = "Npay"
            paymentLog.paymentProcessor = "paystack";
            paymentLog.purpose = "Fund Developer Wallet";
            paymentLog.userEmail = userData.email;
            await  createPaymentLog(paymentLog).then(() =>{
              this.$refs.paystack.click()
              console.log("Done With creating Payment Log")
            })
          },
          formatWithCommas(value) {
            if (!value) return '0.00';
            return new Intl.NumberFormat('en-US', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }).format(value);
          },
          async handleShowPaymentModal(depositAmount){
            if (!depositAmount){
              this.$displaySnackbar({
                message: "Deposit Amount cannot be empty",
                success: false,
              });
            }
            else {
              this.handleShowFundWalletModal()
              this.depositAmount = depositAmount
              await this.createFundWalletPaymentLog(depositAmount)
            }
          },
          handleVerifyPaysackPayment(){
            let data = {
              "paystackData":{
                "reference":this.reference
              }
            }
            verifyPaystackPayment(data).then(() => {
              this.getWallet()
            })
          },
          handleClosePaytackModal(){

          },
          async getWallet(){
            this.loading = true
            let userData = JSON.parse(sessionStorage.getItem("userData"))
            let data = {}
            data.userEmail = userData.email
            await getWalletByUserEmail(data).then(async res => {
              console.log(res.data)
              console.log("Npay Response API call -------------")
              this.walletData = res.data.wallet
              this.getMonthlySpending(this.walletData.transactions)
              // this.walletData.balance = this.walletData.balance /100
              if(this.walletData.transactions) {
                this.walletData.transactions.sort(function (a, b) {
                  return (
                      dayjs(b.createdAt).toDate() -
                      dayjs(a.createdAt ).toDate()
                  );
                });
              }
            }).finally(()=> this.loading = false)
          },
          getMonthlySpending(transactions){
            this.monthlySpending = 0
            if (transactions) {
              transactions.forEach(transaction => {
                if (dayjs(transaction.transactionDate).month() === dayjs().month() && transaction.transactionType === 'DEBIT') {
                  this.monthlySpending += transaction.amount
                }
              })
            }
          },
          getDate(date){
            const today = new Date();
            const yesterday = new Date();
            yesterday.setDate(today.getDate() - 1);
            if (dayjs(date).toDate().toDateString()=== today.toDateString()) {
              return "Today";
            } else if (dayjs(date).toDate().toDateString() === yesterday.toDateString()) {
              return "Yesterday";
            } else {
              return dayjs(date).toDate().toLocaleDateString("en-US", { month: "short", day: "numeric", year: "numeric" });
            }
          },
            handleShowFundWalletModal() {
                this.showFundWalletModal = !this.showFundWalletModal
            },
            async copyCode(code, type) {

                try {
                    await navigator.clipboard.writeText(code);
                    if (type === 'apiKey') {
                        this.copiedApiKey = true;
                        this.copiedBaseUrl = false
                    } else {
                        this.copiedBaseUrl = true
                        this.copiedApiKey = false
                    }

                } catch ($e) {
                    console.log("");
                }
            },
          routeToWallet(){
            sessionStorage.setItem("dashboardComponent", "wallet");
            this.$store.dispatch("dashboardComponent/setCurrentComponent", "wallet");
            this.$router.push({name: 'Wallet'})
            window.location.reload()
          },
          async getAllTC(){
            let data = {}
            data.apiKey = this.developerData.apiKey
            this.loading = true
            let companies = []
            await getAllTransportCompanies(data).then(res =>{
              res.data.splice(res.data.findIndex(v => v.transportCompanyId === 38), 1);
              companies = res.data
              companies.forEach(company => company.subscribed = false)
              this.developerData.transportCompaniesSubscribeTo.forEach(subscribedCompany => {
                companies.forEach(company => {
                  if (subscribedCompany.transportCompanyId === company.transportCompanyId) {
                    company.subscribed = true
                  }
                })
              })
              this.$store.dispatch('companyStore/setTransportCompanies', companies)
              this.loading = false

            })
                .catch(() =>{
                  this.loading= false
                })
          },
        },
       async created() {
         console.log("________________________>>>>>>>>>>")
            this.resourceServer = process.env.VUE_APP_RESOURCE_SERVER_URL
            this.authServer = process.env.VUE_APP_AUTH_BASE_URL

            await this.getAllTC()
           await this.getWallet()
        }

        
    }
</script>
<style lang=scss scoped>
    .dashboard {
        background-color: #F5F7F9;
        min-height: 90vh;
        width: 100%;

        @media screen and (max-width:1470px) {
            padding: 3rem 3rem 0 2rem;

        }

        @media screen and (max-width:1264px) {
            padding: 3rem 7rem 0 7rem;

        }

        @media screen and (max-width:768px) {
            padding: 2rem 1rem;

        }


    }

    .username {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 25px;
        line-height: 24px;
        color: #1D262D;
    }

    .first-column {
        width: 30%;
        max-height: 539px;
        height: 539px;

        @media screen and (max-width:1264px) {
            width: 100%;
            padding: 50px 0;
        }
    }

    .second-column {
        width: 23%;
        margin: 0 40px;
        max-height: 539px;
        height: 539px;

        @media screen and (max-width:1264px) {
            width: 100%;
            padding: 50px 0;
            margin: 0;
        }

        @media screen and (max-width: 1024px) {
            margin-top: 10rem;
        }
    }

    .v-card {

        box-shadow: 0px 8px 5px -8px rgba(54, 54, 54, 0.1) !important;
        border-radius: 15px;
    }

    .body {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        height: auto;

        @media screen and (max-width:1264px) {
            flex-direction: column !important;
            flex-wrap: nowrap
        }
    }

    .v-btn {
        background: linear-gradient(114.35deg, #004AAD 2.72%, #9500AD 181.26%);
        ;
        box-shadow: 0px 2px 8px rgba(130, 130, 130, 0.15);
        border-radius: 10px;
        font-family: 'Inter';
        font-weight: 500;
        font-size: 12px;
        color: #FFFFFF;
        padding: 20px 10px !important;
    }

    .v-card__text {
        font-family: 'Inter';
        font-weight: 550;
        line-height: 15px;
        font-size: 16px;
        color: rgba(79, 79, 79, 0.62);
    }

    .v-card__title {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 36px;
        line-height: 44px;
        color: #001127;

    }

    .api-input {

        margin-top: 15%;
        margin-left: 10px;

    }


    .api-title {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 22px;
        color: rgba(0, 17, 39, 0.75);
    }


    .card-2 {
        padding: 1rem;
        margin-top: 1.5rem;
        box-shadow: 0 8px 5px -8px rgba(54, 54, 54, 0.1) !important;
        background: var(--offWhite);
        @media screen and (max-width: 1440px) {
            margin-top: 2rem;
        }

    }

    .wallet {
        width: 25%;

        @media screen and (max-width:1530px) {
            display: none;
        }
    }

    .card-4 {
        width: 100%;
        height: 182px;
    }

    .card-4 {
        background: rgba(253, 255, 252, 0.06);
        border: 1.5px solid rgba(0, 17, 39, 0.06);
        box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.04);
        border-radius: 15px !important;
    }

    .four-text {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 22px;
        color: rgba(0, 17, 39, 0.753) !important;

    }

    .card-4 .v-card__title {
        font-family: 'Inter', sans-serif;
        font-weight: 500;
        font-size: 28px;
        line-height: 34px;
        color: #001127;
    }

    .amount-spent {
        width: 75%;

        @media screen and (max-width: 1530px) {
            width: 100%;
        }
    }

    .card-4 .v-card__subtitle {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 15px;
        color: rgba(79, 79, 79, 0.62);

    }

    .third-column {
        width: 36%;
        max-height: 839px;
        height: 839px;

        @media screen and (max-width:1264px) {
            width: 100%;
        }

        @media screen and (max-width: 1024px) {
            margin-top: 16rem;
            height: auto;
        }
    }

    .chart-div {
        justify-content: center;
        align-items: center;
    }

    .card-5 {
        background-color: var(--offWhite) !important;
        border: 1.5px solid rgba(0, 17, 39, 0.06);
        box-shadow: 0 4px 60px rgba(0, 0, 0, 0.04);
        border-radius: 20px !important;
        margin-top: 5rem;
        height: 400px;

    }

    .card-5 .v-card__title {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 22px;
        color: rgba(0, 17, 39, 0.75);


    }

    .cardqueen {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        height: 550px;
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 550px;
    }

    .transaction-div {
        background: rgba(253, 255, 252, 0.06);
        border: 1.5px solid rgba(0, 17, 39, 0.06);
        border-radius: 20px !important;
        width: 100%;
        height: 665px;

    }

    .card-6 {
        width: 100%;
        height: 80px;
        background-color: #fdfffc !important;
        border: 1px solid rgba(0, 17, 39, 0.06);
        margin: 0 !important;
        padding: 10px;
        margin: 12px !important;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .cardqueen::-webkit-scrollbar {
        background: transparent !important;
        width: 5px;
        height: 8px;
    }

    .cardqueen::-webkit-scrollbar-thumb {
        background: #c4c4c4 !important;
        max-height: 20px !important;
        border-radius: 6px;
    }

    .six-text {
        font-family: 'Inter',sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 17px;
        width: 25%;
        margin-right: 5%;

    }

    .amount {
        font-family: 'Inter',sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 22px;
        color: rgba(0, 17, 39, 0.75);
        width: 35%;

    }

    .card-6 .six-text2 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 12px;
        text-align: right;
        color: #3F4D5D;
        width: 45%;


    }

    .cardqueen-title {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 22px;
        color: rgba(0, 17, 39, 0.75);

    }
</style>