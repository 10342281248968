<template>
  <div class="tw-flex tw-w-full tw-h-auto tw-justify-center tw-items-center">
    <v-col sm=12 lg="7" class="left-side lg:tw-flex">
      <v-col sm="12" class="left-side-body">
        <img src="@/assets/whitelogo.png" alt="logo" style="height: 40px; width: 186px" @click="$router.push({name : 'HomePage'})">
        <label class="buddy">Hi Dev!</label>
        <h6 class="welcome tw-mt-5">Welcome  Back.</h6>
        <p class="admin tw-mt-5">Developer Dashboard</p>
      </v-col>
    </v-col>
    <v-col sm="12" lg="5" class="right-side tw-flex tw-items-start lg:tw-items-center">
      <v-col sm="12" class="right-side-body">
        <img src="@/assets/bluelogo.png" alt="logo" style="height: 34px; width: 136px;" class="mb-3 mt-6" @click="$router.push({name : 'HomePage'})">

        
        <h6 class="welcome tw-mt-0">Welcome Back!</h6>
        <p class="admin tw-mt-1">Developer Admin Board</p>

        <h6 class="signup-header mb-1 mb-md-5">Sign In</h6>


        <validation-observer ref="observer" v-slot="{ handleSubmit }">

          <form @submit.prevent="handleSubmit(LoginDeveloper)">
        <ValidationProvider name="Email" rules="required" v-slot="{ classes, errors }">
          <div class="tw-pt-4 mb-2" :class="classes">
            <v-text-field placeholder="Email" solo hide-details type="email" v-model="loginData.username" required></v-text-field>
            <span>{{ errors[0] }}</span>
          </div>
        </ValidationProvider>

        <ValidationProvider name="Password" rules="required" v-slot="{ classes, errors }">
          <div class="tw-pt-4 mb-1" :class="classes">
            <v-text-field
              hide-details
              :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="() => (value = !value)"
              :type="value ? 'text' : 'password'"
              solo
              placeholder="Password"
              name="password"
              v-model="loginData.password"
              color="#1E5770"
              id="password">
            </v-text-field>
            <span>{{ errors[0] }}</span>
          </div>
        </ValidationProvider>

        <div class="tw-pt-0">
          <v-checkbox hide-details class="terms" v-model="keepMeIn">
            <template #label>
              <label>
                Keep Me Logged in
              </label>
            </template>
          </v-checkbox>
        </div>

        <base-button class="mt-4" width="100%" button-text="Log In" color="#1E5770" :loading="loading" type="submit"></base-button>
          </form>
        </validation-observer>
        <div class="tw-pt-4">
          <h6 class=" tw-flex already  tw-items-center tw-justify-center">
            Forget Password? <router-link :to="{name: 'ResetPassword'}" style="color: #1E5770; cursor: pointer">&nbsp;Click Here</router-link>
          </h6>

          <h6 class=" tw-flex already  tw-items-center tw-justify-center mt-2">
            Don't have an account? <router-link :to="{name: 'Register'}" style="color: #1E5770;; cursor: pointer">&nbsp;Register</router-link>
          </h6>
        </div>
      </v-col>
    </v-col>

  </div>
</template>

<script>

import BaseButton from "../components/reuseables/BaseButton";
import check from '../assets/check_one.svg'
import {login} from "../services/api/AuthApiService";
import {mapGetters} from "vuex";

export default {
  name: "DeveloperLoginView",
  components: { BaseButton },
  data(){
    return{
      title: "Welcome back.",
      description: "Transport Company Admin Board",
      value : false,
      confirmValue : false,
      dialog : false,
      keepMeIn : false,
      loading : false,
      check,
      loginData:{
        username: "",
        password : "",
      }
    }
  },
  computed:{
    ...mapGetters("developerData",["developerData"])
  },
  methods:{
    toggleModal(){
      this.dialog = !this.dialog
    },
   async LoginDeveloper(){
        this.loading = true
       login(this.loginData).then(async () => {
         let userData = JSON.parse(sessionStorage.getItem("userData"))
         let data = {}
         data.email = userData.email
         await  this.$store.dispatch("developerData/setDeveloperData",data).then(() => {
           if(!this.developerData.onboarded){
              this.$router.push({name: "CompanyInfo"})
           }
           else {
             this.$router.push({name: "MainDashboard"})
           }
         })
          this.loading = false
        }).catch(err => {

              this.$displaySnackbar({
                message: err.response.data.message,
                success: false
              })
              this.loading = false
            }).finally(() => this.loading = false)
      },

  }
}
</script>

<style scoped lang="scss">
.title{
  font-family: "Inter", serif !important;
  font-style: normal;
  font-weight: bold;
  font-size: 20px !important;
  line-height: 26px;
  color: #1E5770;
}
.input{
 width: 48%;
  @media screen  and (max-width: 1024px){
    width: 100%;
  }
}
.agree{
  font-family: "Inter",serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.2px;
  color: rgba(0, 0, 0, 0.7);
}
.have-account{
  font-family: "Inter", serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
}
.forgot-password{
  font-family: "Inter",serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: rgba(117, 115, 115, 0.7);
}

.left-side{
  width: 100%;
  min-height: 100vh;
  height: 100%;
  background-image: url('../assets/login-bg.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0!important;

  @media screen and (max-width: 1024px){
    display: none;
  }
}
.left-side-body{
  display: flex;
  width: 100%;
  flex-direction: column;
  padding:5rem 7rem;
  justify-content: center;
}

.right-side{
  width: 100%;
  min-height: 100vh;
  height: auto;
  padding: 0!important;
  background: white;
}

.right-side-body{
  display: flex;
  // background: red;
  width: 100%;
  flex-direction: column;
  padding:7rem;
  justify-content: center;
  // align-items: center;
  @media screen and (max-width: 1440px ){
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  @media screen and (max-width: 1366px ) and (min-width: 1025px){
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  @media screen and (max-width: 1024px ){
    padding: 1.5rem;
  }
}
.right-side-body >img{
  @media screen and (min-width: 1024px){
    display: none;
  }
}

.right-side-body .welcome{
  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #4F4F4F;
  text-shadow: 0 4px 40px rgba(0, 0, 0, 0.25);
  margin-left: 0;
  @media screen and (min-width: 1024px){
    display: none;
  }
}

.right-side-body .admin{
  font-family: 'Inter',serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.05em;
  color: #4F4F4F;
  margin-left: 0;
  @media screen and (min-width: 1024px){
    display: none;
  }
}

.title{
  font-family: "Inter",serif !important;
  font-style: normal;
  font-weight: bold;
  font-size: 20px !important;
  line-height: 26px;
  color: #1E5770;;
}
.input{
 width: 48%;
  @media screen  and (max-width: 1024px){
    width: 100%;
  }
}
.agree{
  font-family: "Inter", serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.2px;
  color: rgba(0, 0, 0, 0.7);
}
.have-account{
  font-family: "Inter", serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
}

.buddy{
  font-family: "Inter", serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.05em;
  color: #FFFFFF;
  margin-top: 4rem;
  margin-left: 0.8rem;
}
.welcome{
  font-family: "Inter",serif;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 35.2px;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  text-shadow: 0 4px 40px rgba(0, 0, 0, 0.25);
  margin-left: 0.8rem;

  @media screen and (max-width: 1024px) {
    margin-top: 35px;
  }
}
.admin{
  font-family: "Inter",serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.05em;
  color: #FFFFFF;
  margin-left: 0.8rem;
}


.signup-header{
  font-family: "Inter", serif;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 64px;
  color: #1E5770;
  @media screen and (max-width: 1024px) {
    margin-top: 20px;
  }
}

.terms{
  font-family: "Inter",serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;
  color: #4F4F4F;
}

.already{
  font-family: "Inter", serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 22px !important;
  color: #4F4F4F !important;
}


</style>