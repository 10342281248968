var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-flex tw-w-full tw-h-auto tw-justify-center tw-items-center"},[_c('v-col',{staticClass:"left-side lg:tw-flex",attrs:{"sm":"12","lg":"7"}},[_c('v-col',{staticClass:"left-side-body",attrs:{"sm":"12"}},[_c('img',{staticStyle:{"height":"40px","width":"186px"},attrs:{"src":require("@/assets/whitelogo.png"),"alt":"logo"},on:{"click":function($event){return _vm.$router.push({name : 'HomePage'})}}}),_c('label',{staticClass:"buddy"},[_vm._v("Hi Dev!")]),_c('h6',{staticClass:"welcome tw-mt-5"},[_vm._v("Welcome Back.")]),_c('p',{staticClass:"admin tw-mt-5"},[_vm._v("Developer Dashboard")])])],1),_c('v-col',{staticClass:"right-side tw-flex tw-items-start lg:tw-items-center",attrs:{"sm":"12","lg":"5"}},[_c('v-col',{staticClass:"right-side-body",attrs:{"sm":"12"}},[_c('img',{staticClass:"mb-3 mt-6",staticStyle:{"height":"34px","width":"136px"},attrs:{"src":require("@/assets/bluelogo.png"),"alt":"logo"},on:{"click":function($event){return _vm.$router.push({name : 'HomePage'})}}}),_c('h6',{staticClass:"welcome tw-mt-0"},[_vm._v("Welcome Back!")]),_c('p',{staticClass:"admin tw-mt-1"},[_vm._v("Developer Admin Board")]),_c('h6',{staticClass:"signup-header mb-1 mb-md-5"},[_vm._v("Sign In")]),_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.LoginDeveloper)}}},[_c('ValidationProvider',{attrs:{"name":"Email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"tw-pt-4 mb-2",class:classes},[_c('v-text-field',{attrs:{"placeholder":"Email","solo":"","hide-details":"","type":"email","required":""},model:{value:(_vm.loginData.username),callback:function ($$v) {_vm.$set(_vm.loginData, "username", $$v)},expression:"loginData.username"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"tw-pt-4 mb-1",class:classes},[_c('v-text-field',{attrs:{"hide-details":"","append-icon":_vm.value ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.value ? 'text' : 'password',"solo":"","placeholder":"Password","name":"password","color":"#1E5770","id":"password"},on:{"click:append":function () { return (_vm.value = !_vm.value); }},model:{value:(_vm.loginData.password),callback:function ($$v) {_vm.$set(_vm.loginData, "password", $$v)},expression:"loginData.password"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('div',{staticClass:"tw-pt-0"},[_c('v-checkbox',{staticClass:"terms",attrs:{"hide-details":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('label',[_vm._v(" Keep Me Logged in ")])]},proxy:true}],null,true),model:{value:(_vm.keepMeIn),callback:function ($$v) {_vm.keepMeIn=$$v},expression:"keepMeIn"}})],1),_c('base-button',{staticClass:"mt-4",attrs:{"width":"100%","button-text":"Log In","color":"#1E5770","loading":_vm.loading,"type":"submit"}})],1)]}}])}),_c('div',{staticClass:"tw-pt-4"},[_c('h6',{staticClass:" tw-flex already  tw-items-center tw-justify-center"},[_vm._v(" Forget Password? "),_c('router-link',{staticStyle:{"color":"#1E5770","cursor":"pointer"},attrs:{"to":{name: 'ResetPassword'}}},[_vm._v(" Click Here")])],1),_c('h6',{staticClass:" tw-flex already  tw-items-center tw-justify-center mt-2"},[_vm._v(" Don't have an account? "),_c('router-link',{staticStyle:{"color":"#1E5770","cursor":"pointer"},attrs:{"to":{name: 'Register'}}},[_vm._v(" Register")])],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }