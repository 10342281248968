import Vue from "vue";
import VueRouter from "vue-router";
// import LandingPageView from "../views/LandingPageView.vue";
import DeveloperRegisterView from "../views/DeveloperRegisterView";
import DeveloperLoginView from "../views/DeveloperLoginView";
import DeveloperResetPasswordView from "../views/DeveloperResetPasswordView";
import DeveloperNewPasswordView from "../views/DeveloperNewPasswordView";
import HomePage from "@/views/HomePage";
import dashboard from "@/views/MainDashboard.vue"
import VueJwtDecode from "vue-jwt-decode";
import OnboardingAccountDetails from "../views/OnboardingAccountDetails";
import {isTokenValid} from "@/services/api/AuthApiService";



Vue.use(VueRouter);

const routes = [{
    path: "/",
    name: "HomePage",
    component: HomePage,
  },
  {
    path: '*',
    redirect: '/404'
  },
  {
    path: "/404",
    name: "errorpage",
    component: () => import("@/components/reuseables/ErrorPage.vue")
  },
  {
    path: "/register",
    name: "Register",
    component: DeveloperRegisterView,
  }, {
    path: "/pricing",
    name: "Pricing",
    component: () => import("@/views/DeveloperPricing.vue")
  },

  {
    path: "/documentationHome",
    name: "DocumentationHome",
    component: () => import("@/components/reuseables/DocumentationHome.vue"),

  },
  {
    path: "/PaidResources",
    name: "PaidResources",
    component: () => import("@/components/reuseables/PaidResources.vue"),

  },
  {
    path: "/login",
    name: "Login",
    component: DeveloperLoginView,
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: DeveloperResetPasswordView,
  },
  {
    path: "/new-password",
    name: "NewPassword",
    component: DeveloperNewPasswordView,
  },
  {
    path: "/awaiting-verification",
    name: "AwaitingVerification",
    component: () => import("../components/reuseables/CompanyAwaitVerification.vue")
  },
  {
    path: "/company-info",
    name: "CompanyInfo",
    component: () => import("@/views/OnboardingCompanyInfo")
  },
  {
    path: "/company-address",
    name: "CompanyAddress",
    component: () => import("@/views/OnboardingCompanyAddress")
  },
  {
    path: "/documentation",
    name: "Documentation",
    component: () => import("@/views/Documentation.vue"),
    meta: {
      userType: "developer"
    },
  },
  {
    path: "/account-details",
    name: "AccountDetails",
    component: OnboardingAccountDetails
  },
  {
    path: "/dashboard",
    name: "MainDashboard",
    component: () => import("@/views/DeveloperDashboard"),
    redirect: {
      name: "Dashboard"
    },
    children: [{
        path: "",
        name: "Dashboard",
        component: dashboard,
        meta: {
          userType: "developer"
        },
      },
      {
        path: "/transport-companies",
        name: "Transport Companies",
        component: () => import("@/views/company/CompanyDashboard.vue"),
        meta: {
          userType: "developer"
        },
      },
      {
        path: "/wallet",
        name: "Wallet",
        component: () => import("@/views/wallet/WalletDashboard.vue"),
        meta: {
          userType: "developer"
        },
      },
      {
        path: "/community",
        name: "Community",
        component: () => import("@/views/DeveloperCommunityView"),
        meta: {
          userType: "developer"
        },
      },
      {
        path: "/setting",
        name: "Settings",
        component: () => import("@/views/DeveloperSetting"),
        meta: {
          userType: "developer"
        },
      }
    ]

  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
      behavior: "smooth"
    };
  },
});
router.beforeEach((to, from, next) => {
  let token = to.query.access_token
  if (token) {
    let decodedToken = VueJwtDecode.decode(token);
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const payLoad = decodeURIComponent(
      atob(base64)
      .split("")
      .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
      .join("")
    );
    sessionStorage.setItem("userData", JSON.stringify(decodedToken));
    sessionStorage.setItem("userToken", JSON.stringify(token));
    router.replace({
      query: null
    }).then()
    token = null
    router.push({
      name: 'Dashboard'
    })
    return payLoad;
  }
  let userData = JSON.parse(sessionStorage.getItem('userData'))
  if (to.meta.userType) {
    if (!userData) {
      next({
        name: 'HomePage'
      })
    }
    else {
      isTokenValid(userData.exp)
    }
  }
  next();
})
export default router;