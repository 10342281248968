import Vue from "vue";
import Vuex from "vuex";
import snackBar from "./snackBar";
import dashboardComponent from "./dashboardComponent";
import developerData from "./developerData";
import walletStore from "@/store/walletStore";
import companyStore from "@/store/companyStore";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    snackBar,
    dashboardComponent,
    developerData,
    walletStore,
    companyStore
  },
});
