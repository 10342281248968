var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-flex tw-w-full tw-h-auto",staticStyle:{"background":"white"}},[_c('v-col',{staticClass:"tw-hidden left-side lg:tw-flex",attrs:{"sm":"12","lg":"5"}},[_c('OnboardingLeft')],1),_c('v-col',{staticClass:"right-side",staticStyle:{"position":"relative"},attrs:{"sm":"12","lg":"7"}},[_c('v-col',{staticClass:"right-side-body",attrs:{"sm":"12"}},[_c('img',{staticClass:"mb-3 mt-6 img-logo",staticStyle:{"height":"34px","width":"136px"},attrs:{"src":require("@/assets/bluelogo.png"),"alt":"logo"}}),_c('p',{staticClass:"welcome tw-mt-0"},[_vm._v("Account Details")]),_c('p',{staticClass:"admin tw-mt-2"},[_vm._v(" We would like to have your account details to ease payments. ")]),_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.saveData)}}},[_c('v-row',{staticClass:"form"},[_c('v-col',{staticClass:"mb-md-4",attrs:{"lg":"6","sm":"12","cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Bank Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('label',{staticClass:"label"},[_vm._v("Bank Name")]),_c('div',{staticClass:"tw-pt-1 mb-1 mt-2",class:classes},[_c('v-combobox',{staticClass:"tw-mt-2",attrs:{"placeholder":"Sterling Bank","solo":"","hide-details":"","type":"text","required":"","items":_vm.banks,"append-icon":"mdi-chevron-down"},model:{value:(_vm.accountDetails.bankName),callback:function ($$v) {_vm.$set(_vm.accountDetails, "bankName", $$v)},expression:"accountDetails.bankName"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('v-col',{staticClass:"mb-md-4",attrs:{"lg":"6","sm":"12","cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Account Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('label',{staticClass:"label"},[_vm._v("Account Type")]),_c('div',{staticClass:"tw-pt-1 mb-1 mt-2",class:classes},[_c('v-combobox',{attrs:{"placeholder":"Current Account","solo":"","hide-details":"","type":"text","items":_vm.accountTypes,"required":"","append-icon":"mdi-chevron-down"},model:{value:(_vm.accountDetails.accountType),callback:function ($$v) {_vm.$set(_vm.accountDetails, "accountType", $$v)},expression:"accountDetails.accountType"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('v-col',{staticClass:"mb-md-4",attrs:{"lg":"6","sm":"12","cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Account Number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('label',{staticClass:"label"},[_vm._v("Account Number")]),_c('div',{staticClass:"tw-pt-1 mb-1 mt-2",class:classes},[_c('v-text-field',{staticClass:"tw-mt-2",attrs:{"placeholder":"0142140595","solo":"","hide-details":"","type":"Number","required":""},model:{value:(_vm.accountDetails.accountNumber),callback:function ($$v) {_vm.$set(_vm.accountDetails, "accountNumber", $$v)},expression:"accountDetails.accountNumber"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('v-col',{staticClass:"mb-md-4",attrs:{"lg":"6","sm":"12","cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Account Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('label',{staticClass:"label"},[_vm._v("Account Name")]),_c('div',{staticClass:"tw-pt-1 mb-1 mt-2",class:classes},[_c('v-text-field',{attrs:{"placeholder":"Aramide Bhadmus","solo":"","hide-details":"","type":"text","required":""},model:{value:(_vm.accountDetails.accountName),callback:function ($$v) {_vm.$set(_vm.accountDetails, "accountName", $$v)},expression:"accountDetails.accountName"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('v-col',{staticClass:"mb-md-4 tw-hidden",attrs:{"lg":"6","sm":"12","cols":"12"}})],1),_c('div',{staticClass:"btn-div tw-flex lg:tw-justify-end tw-justify-center"},[_c('router-link',{staticClass:"btn-div-text",attrs:{"to":{ name: 'CompanyAddress' }}},[_c('img',{staticClass:"btn-div-img mr-3",attrs:{"src":require("../assets/arrow-back.svg"),"alt":""}}),_c('span',[_vm._v(" Previous ")])]),_c('v-btn',{staticClass:"submit",attrs:{"text":"","type":"submit","disabled":!_vm.formIsValid,"loading":_vm.loading}},[_c('span',[_vm._v(" Done ")]),_c('img',{staticClass:"btn-div-img ml-3",attrs:{"src":require("../assets/arrow_forward.svg"),"alt":""}})])],1)],1)]}}])})],1)],1),_c('modal',{attrs:{"dialog":_vm.dialog,"title":"Great, You're all Set ","additional-text":"You’re ready to start managing your transport Company.","icon":_vm.check,"bottomText":"Go To Dashboard"},on:{"close":_vm.toggleModal}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }