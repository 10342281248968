<template>
  <footer class="footer">
    <div
      class="
        tw-flex tw-justify-center
        md:tw-items-start md:tw-flex-row
        tw-flex-col tw-flex-wrap
      "
    >
      <v-col sm="12" md="4" class="first-box">
        <div class="logo-div">
          <img
            :src="logo"
            alt=""
            @click="routeToHome"
            class="clickable-logo"
            width="146"
            height="40"
          />
        </div>
        <div class="tw-flex tw-items-start tw-mt-5 address-box">
          <img src="../assets/location.svg" alt="" />
          <div>
            <p class="address-text lg:tw-pr-4">
              371 Borno Way, Alagomeji, Yaba, Lagos
            </p>
          </div>
        </div>
        <SocialMedia />
      </v-col>
      <v-col sm="6" md="2" class="second-box">
        <p class="header">Company</p>
        <div
            class="tw-cursor-pointer tw-pt-3 dev-class"
            @click="routeToMain('/about')"
        >
          About Us
        </div>
        <!--        <router-link to="/">Why RoadPadi</router-link>-->
        <!--        <router-link to="/">Services</router-link>-->
        <router-link to="/register">
          Documentation
        </router-link>
        <div
            class="tw-cursor-pointer tw-pt-3 dev-class"
            @click="routeToMain('/career')"
        >
          Career
        </div>
        <div
            class="tw-cursor-pointer tw-pt-3 dev-class"
            @click="routeToMain('/terms-and-policies')"
        >
          Terms & Policies
        </div>
      </v-col>
      <v-col sm="6" md="2" class="third-box">
        <p class="header">Explore</p>
        <div
            class="tw-cursor-pointer tw-pt-3 dev-class"
            @click="routeToMain('/booking')"
        >
          Book Trip
        </div>
        <router-link to="/">Learn</router-link>

        <div
            class="tw-cursor-pointer tw-pt-3 dev-class"
            @click="routeToMain('/blog')"
        >
          Blog
        </div>

        <!--        <router-link to="/">Clients</router-link>-->
      </v-col>
      <v-col sm="6" md="2" class="third-box">
        <p class="header">Help</p>
        <div class="tw-cursor-pointer tw-pt-3 dev-class"
            @click="routeToMain('/contact')">
          Contact us
        </div>
        <div class="tw-cursor-pointer tw-pt-3 dev-class"
             @click="routeToMain('/faqs')">
          F.A.Q
        </div>
      </v-col>
      <v-col sm="6" md="2" class="third-box">
        <p class="header">News Letter</p>
        <p class="box-text">Stay connected with our News Letter</p>
        <form class="tw-w-full" @submit.prevent="subscribe">
          <v-text-field
            type="email"
            placeholder="Your Email Address"
            class="tw-w-full border"
            solo
            v-model="subscriberEmail"
          />
          <div>
            <v-btn text :loading="loading" type="submit" class="lg:tw-mt-2"
              >Get News</v-btn
            >
          </div>
        </form>
      </v-col>
    </div>

    <div class="right-reserved">
      <div class="right-reserved-inner">
        All rights Reserved
        <img
          src="@/assets/copy.svg"
          class="tw-mx-2"
          style="width: 22px; height: 22px"
          alt=""
        />
        {{ year }}
      </div>
    </div>
  </footer>
</template>

<script>
import SocialMedia from "@/components/reuseables/SocialMedia.vue";
import { subscribeToNewsLetter } from "@/services/api/APIService";
export default {
  components: {
    SocialMedia,
  },
  name: "Footer",
  data() {
    return {
      year: "",
      subscriberEmail: "",
      loading: false,
      logo: "https://res.cloudinary.com/myroadpadi/image/upload/v1702625774/production_asset/blue-logo_oucnpq.png",
    };
  },

  methods: {
    routeToHome() {
      if (this.$route.name !== "HomePage")
        this.$router.push({ name: "HomePage" });
    },
    routeToMain(route){
      window.open(process.env.VUE_APP_MAIN_FRONTEND_URL+route, "_self")
    },
    getYear() {
      let date = new Date();
      this.year = date.getFullYear();
    },
    subscribe() {
      if (this.subscriberEmail) {
        this.loading = true;
        let data = {};
        data.subscriberEmail = this.subscriberEmail;
        subscribeToNewsLetter(data)
          .then((res) => {
            this.loading = false;
            this.$displaySnackbar({
              message: res.data.Detail,
              success: true,
            });
          })
          .catch((err) => {
            this.$displaySnackbar({
              message: err.response.data.details[0],
              success: false,
            });
            this.loading = false;
          });
      } else {
        this.$displaySnackbar({
          message: "Please enter your email address",
          success: false,
        });
      }
    },
  },
  mounted() {
    this.getYear();
  },
};
</script>

<style lang="scss" scoped>
.footer {
  padding: 11rem 11rem 0 11rem;
  min-height: 70vh;
  @media screen and (max-width: 1264px) {
    padding: 2rem;
  }
  @media screen and (max-width: 1024px) {
    padding: 1.5rem;
  }
  @media screen and (max-width: 768px) {
    padding: 0.8rem;
  }
  @media screen and (max-width: 360px) {
    padding: 0.3rem;
  }
  position: relative;
  background: #f6f8fc;

  .header {
    font-family: "Raleway", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0;
    text-align: left;
  }

  .first-box {
    .logo-div {
      padding-bottom: 16px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    }
    .address-box {
      img {
        margin-top: 7px;
        margin-right: 8px;
      }
    }
    .address-text {
      height: 72px;
      width: 269px;
      font-family: "Inter", sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 36px;
      letter-spacing: 0;
      text-align: left;
      color: rgba(38, 50, 56, 1);

      @media screen and (max-width: 768px) {
        font-size: 16px;
      }
    }
  }

  .second-box a,
  .third-box a {
    display: block;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    color: rgba(38, 50, 56, 1);

    margin-top: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
    @media screen and (max-width: 768px) {
      font-size: 14px;
      margin-top: 15px;
    }
  }
  .box-text {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    margin: 18px 0;

    font-style: normal;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
  }

  .right-reserved {
    margin-top: 10rem;
    bottom: 0;
    width: 100%;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
  }
  .right-reserved-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-right: 5rem !important;
    @media screen and (max-width: 768px) {
      padding-right: 0 !important;
    }
  }

  .third-box {
    input {
      border: 1px solid rgba(38, 50, 56, 0.461);
      padding: 10px;
      height: 42px;
      font-size: 12px;
      border-radius: 5px;
      color: rgba(38, 50, 56, 0.761) !important;
      @media (max-width: 960px) {
        width: 180px;
      }
    }
    input:focus {
      outline: 1px solid rgba(38, 50, 56, 0.261) !important;
      border: none;
    }
    button {
      height: 40px;
      width: 124px;
      background: #004aad;
      box-shadow: 0 20px 50px rgba(0, 0, 0, 0.05);
      border-radius: 10px;
      color: white;
      font-family: "Inter", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0;
      text-align: center;
      text-transform: none;
    }
  }
}
.dev-class {
  @media screen and (max-width: 768px) {
    font-size: 14px !important;
  }
}
</style>
